
.kv-avatar .krajee-default.file-preview-frame,
.kv-avatar .krajee-default.file-preview-frame:hover
{
    margin: 0;
    padding: 0;

    text-align: center;

    border: none;
    box-shadow: none;
}

.kv-avatar
{
    display: inline-block;
}

.kv-avatar .file-footer-caption
{
    display: none;
}

.kv-avatar .file-thumbnail-footer
{
    height: auto !important;
}

.kv-avatar .file-footer-buttons
{
    text-align: center;
}

.kv-avatar .file-footer-buttons
{
    float: none;
}

.kv-avatar .file-input
{
    display: table-cell;

    width: 100%;
    padding-top: 26px;
}

.kv-avatar .file-default-preview > img
{
    width: 100%;
    max-width: 200px;
}

.file-preview
{
    /*width: 90%;*/
    margin: 0 auto 5px auto;
}

.kv-reqd
{
    font-family: monospace;
    font-weight: normal;

    color: red;
}
