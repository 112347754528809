.select2-container--classic .select2-selection--single:focus
{
    border: 1px solid $primary;
}

.select2-container--default .select2-results__option--highlighted[aria-selected]
{
    color: white;
    background-color: $primary;
}

.select2-container--classic .select2-selection--multiple:focus
{
    border: 1px solid $primary;
}

.select2-container--classic.select2-container--open .select2-dropdown
{
    border-color: $primary;
}

.select2-dropdown
{
    border-radius: 0;
    background-color: $input-bg;
}



.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--single
{
    border-radius: 0;
}


.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--single
{
    height: 38px;

    border: 1px solid $gray-200;
    border-radius: $border-radius;
}

.is-invalid-select2
{
    border-color: $red !important;
}

.select2-container--default.select2-container--focus .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--single
{
    color: $input-color;
    border-color: lighten($component-active-bg, 25%);
    outline: 0;
    background-color: $input-bg;
    -webkit-box-shadow: 0 0 0 .2rem rgba(red($primary), green($primary), blue($primary), .2);
    box-shadow: 0 0 0 .2rem rgba(red($primary), green($primary), blue($primary), .2);
}


.select2-container--default
{
    width: 100% !important;
}

.select2-container .select2-selection--single, .select2-container .select2-selection--multiple
{
    height: 38px;
    min-height: 38px;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered,
.select2-container--default .select2-selection--single .select2-selection__rendered
{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 0 5px;

    list-style: none;
}

.select2-container--default .select2-selection--single .select2-selection__arrow
{
    position: absolute;
    top: 1px;
    right: 1px;

    width: 20px;
    height: 38px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered
{
    line-height: 38px;

    color: #444;
}
